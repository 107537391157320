
.MuiSelect-select, .MuiInputBase-input, .MuiOutlinedInput-input {
    min-width: 100% !important;
}

.css-eimhud-MuiSvgIcon-root{
    font-size: large !important;
}

.editIcon{
    margin-top: 20px;
    color:whitesmoke;
    font-size: medium;
    cursor: pointer;
}

.deleteIcon{
    margin-top: 20px;
    color:red;
    cursor: pointer;
}

.link{
    width:150px
}

.inslink{
    width:180px
}

.bttn{
    display: flex;
    justify-content: end;
}

.radio-btn{
color:white !important
}


